<template>
  <v-container class="pl-10 pr-10" fluid>
    <v-slide-x-transition mode="out-in">
      <div v-if="selectedView === '1'" key="1">
        <!-- <v-row class="mb-5">
          <v-col cols="1">
            <h1>
              Users
            </h1>
          </v-col>
          <v-col>
            <h4 class="mt-4 green--text">
              Total Users: {{ users.length }}
            </h4>
          </v-col>
        </v-row> -->
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <div>Users <span class="ml-4 overline green--text">Total Users: {{ users.length }}</span></div>
            <v-text-field
              v-model="search"
              style="margin-left: 30px;"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <download-excel
              style="padding-right: 10px; cursor: pointer;"
              class="overline green--text pl-5"
              :data="users"
              :fields="columnsToExport"
              :name="nameOfExport"
            >
              Export
              <v-icon
                large
                color="green"
              >
                mdi-microsoft-excel
              </v-icon>
            </download-excel>
          </v-card-title>
          <!-- <div style="text-align: right;">
            <download-excel
              style="padding-right: 20px;"
              :data="users"
              :fields="columnsToExport"
              :name="nameOfExport"
            >
              Export
              <v-icon
                large
                style=" cursor: pointer;"
                color="green"
              >
                mdi-microsoft-excel
              </v-icon>
            </download-excel>
          </div> -->
          <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
            :sort-by.sync="columnName"
            :sort-desc.sync="isDescending"
          >
            <!-- <template v-slot:item.active="{ item }">
              <span :class="item.active ? 'green--text font-weight-bold text-uppercase' : 'red--text text-uppercase'">{{ item.active ? 'Y' : 'N' }}</span>
            </template> -->
            <template v-slot:item.id="{ item }">
              <span>
                {{ item.id }}
                <span />
              </span>
            </template>
            <template v-slot:item.name="{ item }">
              <span :class="item.active ? 'green--text text-uppercase' : 'red--text text-uppercase'">
                {{ item.name }}

                <v-tooltip right>
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      small
                      :color="item.active ? 'green' : 'red'"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span v-if="!item.active">User never activated (completed registration) his/her account</span>
                  <span v-else>User is activated - completed registration</span>
                </v-tooltip>
                <span />
              </span>
            </template>
            <template v-slot:item.gateway="{ item }">
              <span v-if="!item.gateway || item.gateway === 'No gateway'">--</span>
              <span
                v-else
                class="font-weight-bold"
                :class="item.gateway.slice(0, 2) === 'X5' ? 'blue--text' : 'green--text'"
              >
                {{ item.gateway.slice(0, 2) }}
                <span class="text-caption black--text">{{ item.gateway.split('_').filter(item => item.includes('V')).join() }}</span>
              </span>
            </template>
            <template v-slot:item.role="{ item }">
              <span class="text-caption" :class="roleMapper(item.role)">{{ item.role }}</span>
            </template>
            <!-- <template v-slot:item.phone="{ item }">
              <span>{{ item.phone || '--' }}</span>
            </template> -->
            <template v-slot:item.myAddress.city="{ item }">
              <span>{{ item.myAddress.city || '--' }}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{ dayjs(item.createdAt).format('HH:mm DD/MM/YY') }}</span>
            </template>
            <template v-slot:top>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>
                    Deleting user
                  </v-card-title>
                  <v-card-text v-if="deleteUserId.role === 'Home Owner'">
                    Are you sure you want to delete this user? if the user is main it will also delete the property and other acounts asociated
                  </v-card-text>
                  <v-card-text v-else class="red--text">
                    Cannot delete tenan or home member
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      v-if="deleteUserId.role === 'Home Owner'"
                      color="red"
                      text
                      @click="deleteItemConfirm"
                    >
                      DELETE
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                fab
                class="mr-2"
                x-small
                color="green lighten-5 elevation-0"
                @click="$router.push(`/user-detail/${item.id}`)"
              >
                <v-icon
                  small
                  color="green"
                >
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn
                fab
                class="mr-2"
                x-small
                color="gray lighten-5 elevation-0"
                @click="viewUser(item); selectedView = '3'"
              >
                <v-icon
                  small
                  color="gray"
                >
                  mdi-view-comfy
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                fab
                class="mr-2"
                color="gray lighten-5 elevation-0"
                @click="editUser(item); selectedView = '2'"
              >
                <v-icon
                  color="gray"
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <!-- <v-btn
                x-small
                fab
                color="red lighten-5 elevation-0"
                @click="deleteItem(item)"
              >
                <v-icon
                  small
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </v-btn> -->
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div
        v-else-if="selectedView === '2'"
        key="2"
        class="pl-5 pr-5"
      >
        <v-row class="mt-5 mb-5">
          <div style="display: flex;">
            <h1>User Profile :</h1>
            <h1 class="green--text">
              {{ editingUser.name }}
            </h1>
          </div>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'; clearEdits"
          >
            Go back
          </v-btn>
        </v-row>
        <v-divider class="mb-5" />
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="green lighten-2">
              <h3 class="white--text">
                User Details
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="editingUser.name"
                  :counter="40"
                  :rules="nameRules"
                  label="Name"
                  required
                />

                <v-text-field
                  v-model="editingUser.email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                />

                <v-text-field
                  v-model="editingUser.phone"
                  :rules="phoneRules"
                  label="Phone"
                  required
                />
                <div class="mb-3 text-h6">
                  Address
                </div>
                <v-row>
                  <v-text-field
                    v-model="editingUser.myAddress.street"
                    class="ml-3"
                    :rules="addressRules"
                    label="Street"
                    required
                  />
                  <v-text-field
                    v-model="editingUser.myAddress.houseNumber"
                    class="pl-5 pr-3"
                    :rules="addressRules"
                    label="House Number"
                    required
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editingUser.myAddress.city"
                    class="ml-3"
                    :rules="addressRules"
                    label="City"
                    required
                  />
                  <v-text-field
                    v-model="editingUser.myAddress.postCode"
                    class="pl-5 pr-3"
                    :rules="addressRules"
                    label="Postcode"
                    required
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editingUser.myAddress.country"
                    class="ml-3"
                    :rules="addressRules"
                    label="Country"
                    required
                  />
                </v-row>
                <v-btn
                  color="success"
                  class="mr-4 mt-4"
                  :disabled="!valid || !name || !email || !phone"
                >
                  <div v-if="btnLoading">
                    <v-progress-circular
                      indeterminate
                      color="white"
                    />
                  </div>
                  <div v-else>
                    Submit
                  </div>
                </v-btn>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel @click="loadUserProperty">
            <v-expansion-panel-header class="orange lighten-2">
              <h3 class="white--text">
                User Property
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-progress-linear
                v-if="pullingData"
                class="mt-2"
                indeterminate
                color="green"
              />
              <div v-else>
                <v-form class="mt-5">
                  <v-row>
                    <v-text-field
                      v-model="userProperty.id"
                      label="Property ID"
                      disabled
                      required
                    />
                    <v-text-field
                      v-model="userProperty.appUserIds"
                      class="ml-8"
                      label="Property Members"
                      disabled
                      required
                    />
                    <v-text-field
                      :value="userProperty.propertyGateway ? userProperty.propertyGateway.gateway : 'Not added'"
                      class="ml-8"
                      label="Property Gateway"
                      required
                    />
                  </v-row>
                  <v-row>
                    <h3 class="mb-3">
                      Address
                    </h3>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="userProperty.propertyNumber"
                      label="Property Number"
                      disabled
                      required
                    />
                    <v-text-field
                      v-model="userProperty.flatNumber"
                      class="ml-8"
                      label="Property Flat Number"
                      disabled
                      required
                    />
                    <v-text-field
                      v-model="userProperty.street"
                      class="ml-8"
                      label="Property Street"
                      disabled
                      required
                    />
                    <v-text-field
                      v-model="userProperty.city"
                      class="ml-8"
                      label="Property City"
                      disabled
                      required
                    />
                    <v-text-field
                      v-model="userProperty.postCode"
                      class="ml-8"
                      label="Property PostCode"
                      disabled
                      required
                    />
                  </v-row>
                </v-form>
                <v-row class="mt-4">
                  <h3>
                    Status
                  </h3>
                </v-row>
                <v-row class="mb-10">
                  <v-checkbox
                    v-model="userProperty.alarm"
                    label="Is Property Secured/Alarmed?"
                    color="success"
                    hide-details
                    class="non-clickable"
                  />
                  <v-checkbox
                    v-model="userProperty.gatewayActive"
                    class="ml-5 non-clickable"
                    label="Gateway active/ connected?"
                    :color="userProperty.gatewayActive ? 'success' : 'error'"
                    hide-details
                  />

                  <v-checkbox
                    v-model="userProperty.ikeaIntegrationEnabled"
                    class="ml-5 non-clickable"
                    label="Ikea Integration"
                    :color="userProperty.ikeaIntegrationEnabled ? 'success' : 'error'"
                    hide-details
                  />
                  <v-btn style="position: relative; top: 20px; margin-left: 10px;" small>
                    <span v-if="!userProperty.ikeaIntegrationEnabled">Activate Ikea</span>
                    <span v-else>Deactivate Ikea</span>
                  </v-btn>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="manageProperty"
                    class="ml-5"
                    style="margin-top: 5px; color: red;"
                    label="Access the property?"
                    color="error"
                    hide-details
                  />
                  <v-btn
                    class="ml-10 mt-1 mb-1 mr-1"
                    :disabled="!manageProperty"
                    color="error"
                  >
                    Manage Property
                  </v-btn>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel @click="loadUserDevices">
            <v-expansion-panel-header class="deep-purple lighten-2">
              <h3 class="white--text">
                User Devices
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-progress-linear
                v-if="pullingData"
                class="mt-2"
                indeterminate
                color="green"
              />
              <div v-else>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #id
                      </th>
                      <th class="text-left">
                        Active
                      </th>
                      <th class="text-left">
                        Property Id
                      </th>
                      <th class="text-left">
                        Users
                      </th>
                      <th class="text-left">
                        Room Id
                      </th>
                      <th class="text-left">
                        Tunable
                      </th>
                      <th class="text-left">
                        Security
                      </th>
                      <th class="text-left">
                        Ieee
                      </th>
                      <th class="text-left">
                        ZigbeeModel
                      </th>
                      <th class="text-left">
                        Battery
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="device in userDevices"
                      :key="device.id"
                    >
                      <td>{{ device.id }}</td>
                      <td>{{ device.active }}</td>
                      <td>{{ device.propertyId }}</td>
                      <td>{{ device.appUserIds }}</td>
                      <td>{{ device.propertyRoomId }}</td>
                      <td>{{ device.tunable }}</td>
                      <td>{{ device.security }}</td>
                      <td>{{ device.ieee }}</td>
                      <td>{{ device.zigbeeModel }}</td>
                      <td>{{ device.battery }}</td>
                      <td>
                        <v-btn
                          dark
                          color="success"
                        >
                          View
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        v-else-if="selectedView === '3'"
        key="3"
        class="pl-5 pr-5"
      >
        <v-row class="mt-5 mb-5">
          <div style="display: flex;">
            <h1>User Profile : &nbsp;</h1>
            <h1 class="green--text">
              {{ editingUser.name }}
            </h1>
            <h4 class="mt-4 purple--text">
              &nbsp;| {{ editingUser.role }}
            </h4>
          </div>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="green"
            @click="redirectToCompactLogs()"
          >
            View Log
          </v-btn>
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'; clearEdits"
          >
            Go back
          </v-btn>
        </v-row>
        <v-card elevation="3" class="mb-6">
          <v-card-title class="cardtitle">
            <v-icon
              medium
            >
              mdi-account
            </v-icon>&nbsp;
            User Details
          </v-card-title>
          <v-card-text class="dark-text">
            <v-row>
              <v-col cols="2">
                Name
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                E-mail
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.email }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Phone
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.phone }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <hr>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="cardsectitle">
                Address
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Street
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.myAddress.street }}
              </v-col>
              <v-col cols="2">
                House Number
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.myAddress.houseNumber }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                City
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.myAddress.city }}
              </v-col>
              <v-col cols="2">
                Postcode
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.myAddress.postCode }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Country
              </v-col>
              <v-col cols="4" class="black-text">
                {{ editingUser.myAddress.country }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <hr>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="cardsectitle">
                Settings
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Push Notification When Alarm
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.pushNotificationWhenAlarm"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.pushNotificationWhenAlarm"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Push When Alarm Change
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.pushWhenAlarmChange"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.pushWhenAlarmChange"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Push When Scene Change
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.pushWhenSceneChange"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.pushWhenSceneChange"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Can Be Admin Of Main Landlord
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.canBeAdminOfMainLandlord"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.canBeAdminOfMainLandlord"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Landlord Mine Properties By Default
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.landlordMinePropertiesByDefault"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.landlordMinePropertiesByDefault"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                User Can Change Scenes
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.userCanChangeScenes"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.userCanChangeScenes"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                User Can Edit/Create Rooms
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.userCanEditCreateRooms"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.userCanEditCreateRooms"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                User Can Edit/Create Scenes
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.userCanEditCreateScenes"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.userCanEditCreateScenes"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Geofence Enabled
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.geofenceEnabled"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.geofenceEnabled"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Landlord Fire Notification
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.landlordFireNotificationEnabled"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.landlordFireNotificationEnabled"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Landlord Leak Notification
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.landlordLeakNotificationEnabled"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.landlordLeakNotificationEnabled"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Landlord Type Of Notification Push
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.landlordTypeOfNotificationPush"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.landlordTypeOfNotificationPush"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Landlord Type Of Notification Mail
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.landlordTypeOfNotificationMail"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.landlordTypeOfNotificationMail"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Landlord Climate Notification
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="editingUser.landlordClimateNotificationEnabled"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!editingUser.landlordClimateNotificationEnabled"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="3" class="mb-6">
          <v-card-title class="cardtitle">
            <v-icon
              medium
            >
              mdi-home
            </v-icon>&nbsp;
            User Property
          </v-card-title>
          <v-card-text class="dark-text">
            <v-row>
              <v-col cols="2">
                Property ID
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.id }}
              </v-col>
              <v-col cols="2">
                Property Members
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.appUserIds }}
              </v-col>
              <v-col cols="2">
                Property Gateway
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.propertyGateway ? userProperty.propertyGateway.gateway : 'Not added' }}
              </v-col>
              <v-col cols="2">
                Active Scene
              </v-col>
              <v-col cols="2" class="black-text">
                {{ extras.sceneActiveName }} ({{ extras.sceneActiveID }})
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <hr>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="cardsectitle">
                Address
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Property Number
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.propertyNumber }}
              </v-col>
              <v-col cols="2">
                Property Flat Number
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.flatNumber }}
              </v-col>
              <v-col cols="2">
                Property Street
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.street }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Property City
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.city }}
              </v-col>
              <v-col cols="2">
                Property Postcode
              </v-col>
              <v-col cols="2" class="black-text">
                {{ userProperty.postCode }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <hr>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="cardsectitle">
                Status
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Is Property Secured/Alarmed?
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.alarm"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.alarm"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Gateway active/ connected?
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.gatewayActive"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.gatewayActive"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Homevision Membership
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.isMember"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.isMember"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
              <v-col cols="2">
                Ikea Integration
              </v-col>
              <v-col cols="2" class="black-text">
                <v-icon
                  v-if="userProperty.ikeaIntegrationEnabled"
                  medium
                  class="green--text"
                >
                  mdi-check-bold
                </v-icon>
                <v-icon
                  v-if="!userProperty.ikeaIntegrationEnabled"
                  medium
                  class="red--text"
                >
                  mdi-close-thick
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="3" class="mb-6">
          <v-card-title class="cardtitle">
            <v-icon
              medium
            >
              mdi-devices
            </v-icon>&nbsp;
            User Devices
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersDevices"
              :items="userDevices"
              :search="searchDeviceTable"
              :footer-props="footerProps"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="searchDeviceTable"
                  label="Search"
                  class="mx-4"
                />
              </template>
              <template v-slot:item.active="{ item }">
                <div :class="item.active ? 'green--text' : 'red--text'">
                  {{ item.active }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Users',
  components: {
  },
  data () {
    return {
      dayjs,
      selectedView: '1',
      showProgress: false,
      dialogDelete: false,
      deleteUserId: {},
      btnLoading: false,
      pullingData: false,
      manageProperty: false,
      pagination: {},
      searchDeviceTable: '',
      search: '',
      users: [],
      columnName: 'createdAt',
      isDescending: true,
      nameOfExport: 'Homevision-Users (' + new Date() + ')',
      columnsToExport: {
        id: 'id',
        name: 'name',
        email: 'email',
        phone: 'phone',
        role: 'role',
        city: {
          field: 'myAddress',
          callback: (value) => {
            return `${value.city}`
          }
        },
        address: {
          field: 'myAddress',
          callback: (value) => {
            return `${value.street}`
          }
        },
        gatewayModel: {
          field: 'gateway',
          callback: (gateway) => {
            let model = 'N/A'
            if (gateway === 'No gateway' || !gateway) {
              model = '---'
            } else {
              model = gateway.slice(0, 2)
            }
            return `${model}`
          }
        },
        gatewayVersion: {
          field: 'gateway',
          callback: (gateway) => {
            let model = 'N/A'
            if (gateway === 'No gateway' || !gateway) {
              model = '---'
            } else {
              model = gateway.split('_').filter(item => item.includes('V')).join()
            }
            return `${model}`
          }
        },
        active: {
          field: 'active',
          callback: (active) => {
            let status = '---'
            if (active) {
              status = 'YES'
            } else {
              status = 'NO'
            }
            return `${status}`
          }
        },
        registeredDate: {
          field: 'createdAt',
          callback: (registered) => {
            return `${dayjs(registered).format('DD/MM/YYYY')}`
          }
        },
        registeredTimes: {
          field: 'createdAt',
          callback: (registered) => {
            return `${dayjs(registered).format('hh:mm:ss')}`
          }
        },
        usingGeofence: {
          field: 'myGeoFences',
          callback: (myGeofences) => {
            return `${!!myGeofences.length}`
          }
        },
        subscribedToNewsletter: {
          field: 'subscribeNewsletter',
          callback: (subscribeNewsletter) => {
            return `${subscribeNewsletter}`
          }
        },
        subscribedToDealsAndOffers: {
          field: 'subscribeDealsOffers',
          callback: (subscribeDealsOffers) => {
            return `${subscribeDealsOffers}`
          }
        }
      },
      headersDevices: [
        { text: '#', value: 'id' },
        { text: 'Active', value: 'active' },
        { text: 'Property Id', value: 'propertyId' },
        { text: 'Users', value: 'appUserIds' },
        { text: 'Room', value: 'propertyRoomId' },
        { text: 'Tunable', value: 'tunable' },
        { text: 'Security', value: 'security' },
        { text: 'Ieee', value: 'ieee' },
        { text: 'ZigbeeModel', value: 'zigbeeModel' },
        { text: 'Battery', value: 'battery' }
      ],
      headers: [
        { text: '#', value: 'id' },
        { text: 'Gateway', value: 'gateway' },
        { text: 'User Role', value: 'role' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        // { text: 'Phone', value: 'phone' },
        { text: 'City', value: 'myAddress.city' },
        { text: 'Registered', value: 'createdAt' },
        // { text: 'Active', value: 'active' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      footerProps: {
        'items-per-page-options': [50, 100, 150, 200]
      },
      editingUser: {
        name: '',
        phone: '',
        email: '',
        myAddress: {
          street: '',
          houseNumber: '',
          city: '',
          country: '',
          postCode: '',
          flatNumber: ''
        }
      },
      userProperty: {
        propertyGateway: {
          gateway: ''
        }
      },
      userDevices: [],
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 10) || 'Name must be at least 10 characters',
        v => (v && v.length <= 40) || 'Name must be less than 40 characters'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (v && v.length >= 10) || 'Name must be at least 9 digits'
      ],
      address: {
        street: '',
        houseNumber: '',
        city: '',
        postCode: '',
        country: 'Sverige'
      },
      addressRules: [
        v => !!v || 'Address is required'
      ],
      checkbox: false,
      extras: {
        sceneActiveName: '',
        sceneActiveID: null
      }
    }
  },
  watch: {
    //         dialog (val) {
    //     val || this.close()
    //   },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    async loadUsers () {
      this.showProgress = true
      this.$store.dispatch('getUsers').then(response => {
        this.users = response.data.filter(item => item.role !== 'Landlord')
        this.showProgress = false
      })
    },
    viewUser (item) {
      this.editingUser = item
      this.loadUserProperty()
      this.loadUserDevices()
    },
    editUser (item) {
      this.editingUser = item
    },
    deleteItemConfirm () {
      const user = this.deleteUserId.id
      this.$store.dispatch('deleteUser', user).then(() => {
        this.closeDelete()
      })
    },
    deleteItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteUserId = item
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.deleteUserId = {}
        this.loadUsers()
      })
    },
    clearEdits () {
      this.editingUser = {
        name: '',
        phone: '',
        email: '',
        myAddress: {
          street: '',
          houseNumber: '',
          city: '',
          country: '',
          postCode: '',
          flatNumber: ''
        }
      }
      this.userProperty = {}
    },
    loadUserProperty () {
      this.pullingData = true
      const userId = this.editingUser.id
      this.$store.dispatch('getProperty', userId).then(response => {
        this.userProperty = response.data
        this.extras = response.data.extras
        this.pullingData = false
      })
    },
    loadUserDevices () {
      this.pullingData = true
      const userId = this.editingUser.id
      this.$store.dispatch('getUserDevices', userId).then(response => {
        this.userDevices = response.data
        this.pullingData = false
      })
    },
    roleMapper (role) {
      if (role === 'Home Owner') {
        return 'blue--text'
      } else if (role === 'Landlord') {
        return 'green--text'
      } else if (role === 'Tenant') {
        return 'red--text'
      } else if (role === 'House Member') {
        return 'purple--text'
      }
    },
    redirectToLogs () {
      this.$router.push({ path: '/propertylog/' + this.userProperty.id })
    },
    redirectToCompactLogs () {
      this.$router.push({ path: '/propertylogcompact/' + this.userProperty.id })
    }
  }
}
</script>

<style scoped>
  .cardtitle {
    font-size: 22px;
    color: #fff;
    background-color: #363636;
    margin-bottom: 20px;
  }
  .cardtitle i{
    color: #fff;
  }
  .cardsectitle {
    font-weight: bold;
    color: #363636;
  }
  .dark-text {
    font-size: 18px;
  }
  .black-text {
    font-weight: bold;
    color: #000;
  }
</style>
